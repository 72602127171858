import React, { Component } from "react";
import Link from "next/link";
import styles from "./Header.module.scss";
import { inject, observer } from "mobx-react";
import { Dropdown } from "semantic-ui-react";
import redirectTo from "../../utils/redirectTo";
import { getPreviousLocation, sendMetrics } from "../../utils/misc";
import SpinnerStore from "../../stores/SpinnerStore";
import DSLogo from "../dsLogo";
import SelectedCityStore from "../../stores/SelectedCityStore";
import UiStore from "../../stores/UiStore";
import ResourceStore from "../../stores/ResourseStore";
import { NextRouter, withRouter } from "next/router";
import CustomCityPicker from "../customCityPicker";
import Spinner from "../spinner";
import { observe } from "mobx";
import UserAvatar from "../ui/userAvatar";

interface Props {
  authStore?: any;
  spinnerStore?: SpinnerStore;
  citiesStore?: ResourceStore;
  selectedCityStore?: SelectedCityStore;
  uiStore?: UiStore;
  toggledMSK?: boolean;
  router: NextRouter;
}

@inject("authStore")
@inject("citiesStore")
@inject("spinnerStore")
@inject("selectedCityStore")
@inject("uiStore")
@inject("tokenStore")
@observer
class Header extends Component<any, any> {
  props: Props;
  state = {
    isMobile: false,
    isMenuOpen: false,
    isPWA: false,
    slice: 2,
  };

  constructor(props) {
    super(props);
    // this.REF_HEADER = React.createRef();
    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleCloseMenuAfterLink = this.handleCloseMenuAfterLink.bind(this);
  }

  logOut = () => {
    this.props.spinnerStore.toggleSpinner();
    this.props.authStore.logOut();
    if (document) {
      document.location.reload();
    }
  };

  isIos = () => {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  };

  isInStandaloneMode = () =>
    "standalone" in window.navigator && window.navigator.standalone;

  componentDidMount() {
    if(this.props?.tokenStore?.token) {
      this.props?.authStore?.fetchCurrentUser();
    }
    this.setState({
      isMobile: window.matchMedia("(max-width: 1200px)").matches,
    });
    // this.REF_HEADER.current.addEventListener(
    //   "click",
    //   this.handleCloseMenuAfterLink
    // );
    if (this.isIos() && this.isInStandaloneMode()) {
      this.setState({ isPWA: true });
    }
  }

  componentWillUnmount() {
    // this.REF_HEADER.current.removeEventListener(
    //   "click",
    //   this.handleCloseMenuAfterLink
    // );
  }

  toProfile = () => {
    redirectTo("/profile");
  };

  toggleMenu(v) {
    /*if (this.state.isMenuOpen) {
                  document.body.style.overflow = 'unset';
                  document.getElementById('__next').style.overflow = 'unset';
                  if (window)
                    window.scrollTo({top: 0, behavior: 'smooth'})
                } else {
                  document.body.style.overflow = 'hidden';
                  document.getElementById('__next').style.overflow = 'hidden';
                  if (window)
                    window.scrollTo({top: 0, behavior: 'smooth'})
                }*/
    this.setState({ isMenuOpen: v });
  }

  logIn = () => {
    sendMetrics("button_pressed");
  };

  handleCloseMenuAfterLink = (e) => {
    if (
      e.target.closest("header") &&
      !e.target.closest("button") &&
      this.state.isMobile
    ) {
      this.setState({ isMenuOpen: false });
    }
  };

  render() {
    const { props } = this;
    const { router } = props;
    const { isMobile, isMenuOpen } = this.state;

    return (
      <div className={styles.NewHeader}>
        <div className={"container d-flex align-items-center"}>
          <div className={styles.NewHeader_First}>
            <DSLogo
              isActiveLink={router.pathname}
              isMenuOpen={isMenuOpen}
              link={"/"}
              metric={"to_main_page_from_header"}
              isMobile={isMobile}
            />
          </div>
          <div className={styles.NewHeader_MobileHelp}>
            <Link
              href="/donate/?utm_source=mobile_header&utm_medium=ds&utm_campaign=donor_search"
            >
              <a className={styles.header_second_favorite}>
                <img src="/static/images/heart.svg" alt="heart" />
                <span>Помочь проекту</span>
              </a>
            </Link>
          </div>
          <div className={styles.NewHeader_Second}>
            <div
              className={styles.NewHeader_Second_Item}
              onClick={() => {
                sendMetrics("to_how_from_header");
                redirectTo("/how/");
              }}
            >
              Как стать донором?
            </div>
            <div
              className={styles.NewHeader_Second_Item}
              onClick={() => {
                sendMetrics("to_bloodstations_from_header");
                redirectTo("/bloodstations/");
              }}
            >
              Где сдать кровь?
            </div>
            <div className={styles.NewHeader_Second_Item}>
              <More>
                <span>
                  Еще <img src={"/static/images/mainDots.svg"} alt={""} />
                </span>
              </More>
            </div>
          </div>
          <div className={styles.NewHeader_Third}>
            <div className={styles.NewHeader_Third_Item}>
              <SelectCity />
            </div>
            <div className={styles.NewHeader_Third_Item}>
              <Link
                href="/donate/?utm_source=header&utm_medium=ds&utm_campaign=donor_search"
              >
                <a className={styles.header_second_favorite}>
                  <img src="/static/images/heart.svg" alt="heart" />
                  <span>Помочь проекту</span>
                </a>
              </Link>
            </div>
            <div className={styles.NewHeader_Third_Item}>
              <Link
                href={
                  "/profile/?utm_source=header&utm_medium=ds&utm_campaign=donor_search"
                }
              >
                <div className={styles.NewHeader_Third_Item_User}>
                  {this.props.authStore?.user ? (
                    <UserAvatar scale={32} user={this.props.authStore.user} />
                  ) : (
                    <Link href={getPreviousLocation()}>
                      <a
                        className={styles.NewHeader_Third_Item_User_Button}
                        onClick={this.logIn}
                      >
                        Войти
                      </a>
                    </Link>
                  )}
                </div>
              </Link>
              {this.props.authStore?.user && (
                <div className={styles.NewHeader_Third_Item_DropDown}>
                  <Dropdown
                    direction={"left"}
                    inline={false}
                    className={styles.header_dropdown_menu}
                  >
                    {
                      !props.authStore.user.managed_organizations.length ?
                      <Dropdown.Menu>
                        <Dropdown.Item text="Личный кабинет" onClick={() => redirectTo('/profile')} />
                        <Dropdown.Item text="Напомнить о донации" onClick={() => redirectTo('/profile/planning')} />
                        <Dropdown.Item text="Добавить донацию" onClick={() => redirectTo('/profile/add_donation')} />
                        <Dropdown.Item text="Мои бонусы" onClick={() => redirectTo('/profile/bonus')} />
                        <Dropdown.Item text="Настройки аккаунта" onClick={() => redirectTo('/profile/settings/personal')} />
                        <Dropdown.Item text="Выйти" onClick={this.logOut} />
                      </Dropdown.Menu>
                      :
                      <Dropdown.Menu>
                        <Dropdown.Item text="Мои организации" icon={<img src="/static/images/dropdown/org.svg"/>} className={styles.labelItem}/>
                        {
                          props.authStore.user.managed_organizations.slice(0, this.state.slice).map((org) => (
                            <Dropdown.Item key={org.id} text={org.name} className={styles.nestedItem} onClick={() => {window.location.href = `${process.env.NEXT_PUBLIC_EVENTS_URL}profile_of_organization/${org.id}`}}/>
                          ))
                        }
                        {
                          props.authStore.user.managed_organizations.length > 2 && 
                          <Dropdown.Item
                            text={this.state.slice === 2 ? 'Показать ещё' : 'свернуть'}
                            className={styles.show}
                            onClick={(e) => {e.stopPropagation(); this.setState({
                              slice: this.state.slice === 2 ? props.authStore.user.managed_organizations.length : 2,
                            })}}
                          />
                        }
                        <Dropdown.Item text="Профиль донора" icon={<img src="/static/images/dropdown/profile.svg"/>} className={styles.labelItem}/>
                        <Dropdown.Item text="Личный кабинет" onClick={() => redirectTo('/profile')} className={styles.nestedItem}/>
                        <Dropdown.Item text="Напомнить о донации" onClick={() => redirectTo('/profile/planning')} className={styles.nestedItem}/>
                        <Dropdown.Item text="Добавить донацию" onClick={() => redirectTo('/profile/add_donation')} className={styles.nestedItem}/>
                        <Dropdown.Item text="Мои бонусы" onClick={() => redirectTo('/profile/bonus')} className={styles.nestedItem}/>
                        <Dropdown.Item text="Настройки аккаунта" onClick={() => redirectTo('/profile/settings/personal')} className={styles.nestedItem}/>
                        <Dropdown.Item text="Выйти" onClick={this.logOut} icon={<img src="/static/images/dropdown/out.svg"/>} className={styles.labelItem}/>
                      </Dropdown.Menu>
                    }
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const More = ({ children }) => {
  return (
    <Dropdown icon={null} trigger={children}>
      <Dropdown.Menu
        style={{
          width: "155px",
          border: "1px solid rgba(255,255,255,0)",
          borderRadius: "6px",
        }}
      >
        <Dropdown.Item className={styles.headerDropdownItem}>
          <div className={styles.header_nav_link}>
            <Link href="/bonus/">
              <a onClick={() => sendMetrics("to_bonuses_from_header")}>
                Бонусы для доноров
              </a>
            </Link>
          </div>
        </Dropdown.Item>
        <Dropdown.Item className={styles.headerDropdownItem}>
          <div className={styles.header_nav_link}>
            <Link href={"/top/"}>
              <a>Топ доноров</a>
            </Link>
          </div>
        </Dropdown.Item>
        <Dropdown.Item className={styles.headerDropdownItem}>
          <div className={styles.header_nav_link}>
            <a
              href="https://journal.donorsearch.org/?utm_source=header&utm_medium=ds&utm_campaign=donor_search"
              target={"_blank"}
            >
              Журнал о донорстве
            </a>
          </div>
        </Dropdown.Item>
        {/*<Dropdown.Item className={styles.headerDropdownItem}>*/}
        {/*  <div className={styles.header_nav_link}>*/}
        {/*    <a*/}
        {/*      href="https://unity.donorsearch.org/?utm_source=header&utm_medium=ds&utm_campaign=donor_search"*/}
        {/*      target={"_blank"}*/}
        {/*    >*/}
        {/*      Афиша мероприятий для доноров*/}
        {/*    </a>*/}
        {/*  </div>*/}
        {/*</Dropdown.Item>*/}
        <Dropdown.Item className={styles.headerDropdownItem}>
          <div className={styles.header_nav_link}>
            <a href="https://market.yandex.ru/business--donorsearch-shop-magazin-soobshchestva-donorov/123729115/?utm_source=header&utm_medium=ds&utm_campaign=donor_search" target={"_blank"}>
              Магазин для доноров
            </a>
          </div>
        </Dropdown.Item>
        <Dropdown.Item className={styles.headerDropdownItem}>
          <div className={styles.header_nav_link}>
            <Link href={"/helpme/"}>
              <a>Найти донора</a>
            </Link>
          </div>
        </Dropdown.Item>
        <Dropdown.Item className={styles.headerDropdownItem}>
          <div className={styles.header_nav_link}>
            <a href="https://unity.donorsearch.org/" target={"_blank"}>
              Мероприятия
            </a>
          </div>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

@inject("citiesStore")
@inject("selectedCityStore")
@observer
class SelectCity extends Component<any, any> {
  props: {
    citiesStore?: ResourceStore;
    selectedCityStore?: SelectedCityStore;
  };
  state: {
    city_name: string;
    isOpen: boolean;
    initialCity: number;
  };

  constructor(props) {
    super(props);
    this.state = {
      city_name: null,
      isOpen: false,
      initialCity: props.selectedCityStore.cityID,
    };
  }

  updateValue = () => {
    if (this.props.selectedCityStore.cityData?.id) {
      this.setState({ city_name: this.props.selectedCityStore.cityData.title });
    } else {
      if (this.props.selectedCityStore.cityID) {
        this.props.citiesStore
          .loadOne(this.props.selectedCityStore.cityID)
          .then((city) => this.setState({ city_name: city.title }))
          .catch(() =>
            this.setState({
              selected_city: { label: "Все регионы", value: 0 },
              city_name: "Все регионы",
            })
          );
      } else {
        this.setState({
          selected_city: { label: "Все регионы", value: 0 },
          city_name: "Все регионы",
        });
      }
    }
  };

  componentDidMount() {
    observe(this.props.selectedCityStore, "cityID", (event) => {
      if (event.newValue !== event.oldValue) {
        this.updateValue();
      }
    });
    this.updateValue();
  }

  componentDidUpdate(prevProps) {}

  togglePopup = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { props, state, togglePopup } = this;
    const { cityID } = props.selectedCityStore;
    const { city_name, isOpen } = state;
    if (!city_name) {
      return (
        <div className={styles.Preloader}>
          <Spinner toggled customHeight={"32px"} customWidth={"32px"} />
        </div>
      );
    }
    return (
      <div onClick={togglePopup} className={styles.headerGeoTrigger}>
        {isOpen && (
          <>
            <div className={styles.custom}>
              <div
                onClick={(e) => e.stopPropagation()}
                className={styles.customPopup}
              >
                <CustomCityPicker />
              </div>
            </div>
            <div className={styles.Overlay} />
          </>
        )}
        <img src="/static/images/mainGeo.svg" alt="" />
        <span>{city_name}</span>
      </div>
    );
  }
}

export default withRouter(Header);
